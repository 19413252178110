<template>
  <v-form ref="form" v-model="valid" class="pa-4 pt-6">
    <div v-for="(item, index) in localDialog.questions" :key="index">
      <p
        style="white-space: pre-line"
        :class="{ 'body-2': $vuetify.breakpoint.xs }"
        class="title font-italic font-weight-medium text-justify"
      >
        {{ `${index + 1}. ${item.question}` }}
        <dialog-data-user
          v-if="item.escortText"
          :data-price="dataPrice"
          :data-studio="dataStudio"
          type-exercise="consultation"
          ><template v-slot:button="{ on, upd }">
            <span
              class="cursorPointer"
              style="color: #42A5F5"
              v-on="on"
              @click="upd"
              v-text="item.escortText"
            /> </template
        ></dialog-data-user>
      </p>

      <v-card-actions v-if="item.audioSrc">
        <audio controls :src="item.audioSrc" type="audio/mpeg">
          <a :href="item.audioSrc">download the audio</a>.
        </audio>
      </v-card-actions>
      <dialog-question-block-image
        v-if="imageBlocksSrc[`block${id}${index}`]"
        :id-block="id"
        :title-studios="titleStudios"
        :index="index"
        :answer-block-img="item.answerBlockImg || ''"
        @changeAnswerImag="changeAnswerBlockImag($event, index)"
        @changeValidImageBlocksSrc="changeValidImageBlocksSrc($event, index)"
      />

      <v-card-actions v-if="item.iframeSrc">
        <iframe
          :src="item.iframeSrc"
          width="100%"
          height="300"
          frameborder="0"
          allow="accelerometer; fullscreen; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-card-actions>
      <v-card-actions>
        <v-img v-if="item.questionImg" :src="item.questionImg" />
      </v-card-actions>
      <microphone
        v-if="item.answerVoice"
        class="mb-3"
        :class="{ 'mt-n6': $vuetify.breakpoint.mdAndUp }"
        :voice-src="item.answerVoice"
        @uploadAudioUrl="changeAudioUrl($event, index)"
        @uploadAudioBlob="changeAudio($event, index)"
      />
      <v-textarea
        v-model="localDialog.questions[index].answer"
        auto-grow
        filled
        color="deep-purple"
        :label="`Ответ ${index + 1}`"
        rows="1"
        counter
        dense
        :rules="dialogRules"
      />
      <input-file
        v-if="item.answerImg"
        :image-src="item.answerImg"
        @changeImageSrs="imageSrcChange($event, index)"
        @changeImage="imageChange($event, index)"
      />
    </div>
    <v-divider />
    <v-col class="text-center">
      <v-btn color="primary" @click="saveDialog">Сохранить</v-btn>
    </v-col>
    <v-divider />
    <paginations
      :local-dialog="localDialog"
      :title-studios="titleStudios"
      :valid="validDialog"
      :parent="parent"
      @changeDone="changeDone"
    />
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'StudioDialog',
  components: {
    DialogQuestionBlockImage: () => import('./DialogQuestionBlockImage'),
    InputFile: () => import('../shared/inputFile/InputFile'),
    Paginations: () => import('../shared/Paginations'),
    Microphone: () => import('@/components/shared/Microphone'),
    DialogDataUser: () => import('@/components/shared/dialog/DialogDataUser')
  },
  props: {
    dialog: {
      type: Object,
      required: true
    },
    titleStudios: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    dataStudio: {
      type: Object,
      required: true
    },
    parent: {
      type: String,
      required: true
    }
  },
  data: () => ({
    localDialog: {},
    valid: false,
    validImageBlocksSrc: true,
    photoPositionUrl:
      'https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/admin%2FemptyPlace.png?alt=media&token=920bf2cc-8e37-4628-a36e-d5cf47dda7f1',
    dialogRules: [
      v => !!v || 'Необходимо ответить',
      v => (v && v.length >= 3) || 'Ответ должен быть не менее 3 символов',
      v => (v && v.length <= 2000) || 'Ответ должен быть не более 2000 символов'
    ]
  }),
  computed: {
    ...mapState({
      listAuthors: state => state.shared.listAuthors
    }),
    validDialog() {
      return (
        this.valid &&
        !this.localDialog.questions.some(
          item => item.answerImg === this.photoPositionUrl || item.answerVoice === 'nonexistent'
        ) &&
        this.validImageBlocksSrc
      )
    },
    imageBlocksSrc() {
      return this.$store.getters.imageBlocksSrc
    },
    author() {
      return this.$store.getters.author(this.dataStudio.author)
    },
    dataPrice() {
      return {
        price: this.author.consultationCost || 100,
        anteaters: '',
        titleStudiosPrice: this.dataStudio.id,
        typeExercise: 'individualLesson',
        status: 'Консультация автора',
        typeGroup: 'singly',
        subscription: false,
        subscriptionIntervalCount: 1
      }
    }
  },
  watch: {
    dialog: {
      deep: true,
      handler() {
        this.localDialog = {
          done: this.dialog.done || false,
          id: this.dialog.id,
          questions: this.dialog.questions.map(item => {
            return { ...item }
          })
        }
      }
    }
  },
  created() {
    this.localDialog = {
      done: this.dialog.done || false,
      id: this.dialog.id,
      questions: this.dialog.questions.map(item => {
        return { ...item }
      })
    }
    if (!this.listAuthors.length) this.$store.dispatch('getListAuthors')
  },
  beforeDestroy() {
    this.$store.dispatch('changeUserStudio', {
      localDialog: {
        done: this.localDialog.done,
        id: this.localDialog.id,
        questions: this.localDialog.questions.map(item => {
          return { ...item }
        })
      },
      titleStudios: this.titleStudios,
      typeExercise: 'studios'
    })
  },
  methods: {
    changeDone() {
      this.localDialog.done = true
    },
    imageSrcChange(imageSrc, index) {
      if (imageSrc === 'default') {
        this.localDialog.questions[index].answerImg = this.photoPositionUrl
        this.localDialog.questions[index].image = null
      } else {
        this.localDialog.questions[index].answerImg = imageSrc
      }
    },
    imageChange(image, index) {
      this.localDialog.questions[index].image = image
    },
    changeAnswerBlockImag(imageSrc, index) {
      this.localDialog.questions[index].answerBlockImg = imageSrc
      if (!this.localDialog.questions.some(item => item.answerBlockImg === this.photoPositionUrl)) {
        this.validImageBlocksSrc = true
      }
    },
    changeValidImageBlocksSrc() {
      this.validImageBlocksSrc = false
    },
    changeAudioUrl(audioUrl, index) {
      if (audioUrl === 'nonexistent') {
        this.localDialog.questions[index].audio = null
      }
      this.localDialog.questions[index].answerVoice = audioUrl
    },
    changeAudio(audio, index) {
      this.localDialog.questions[index].audio = audio
    },
    saveDialog() {
      this.$store.dispatch('changeUserStudio', {
        localDialog: {
          done: this.localDialog.done,
          id: this.localDialog.id,
          questions: this.localDialog.questions.map(item => {
            return { ...item }
          })
        },
        titleStudios: this.titleStudios,
        typeExercise: 'studios'
      })
    }
  }
}
</script>

<style scoped />
